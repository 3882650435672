<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Point Teman Treasury
            </b-card-header>
            <b-card-body>
                <point></point>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Point from './Table'
export default {
    name: 'index-point',
    components: {
        Point,
    },
}
</script>